import React from "react";
import {graphql, StaticQuery} from "gatsby";
import Img from "gatsby-image";

export const ImageLoader = ({filename}) => {

    const query = graphql`
        query {
            bigchoir: file(relativePath: { eq: "choir.jpg" }) {
                childImageSharp {
                    fixed(width: 457, height: 240) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            choir_christmas: file(relativePath: { eq: "choir_christmas.jpg" }) {
                childImageSharp {
                    fixed(width: 450, height: 299) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            neptunes: file(relativePath: { eq: "neptunes.jpg" }) {
                childImageSharp {
                    fixed(width: 517, height: 240) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            persephones: file(relativePath: { eq: "persephones.jpg" }) {
                childImageSharp {
                    fixed(width: 489, height: 240) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            chamber: file(relativePath: { eq: "chamber.jpg" }) {
                childImageSharp {
                    fixed(width: 644, height: 240) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            barbara: file(relativePath: { eq: "barbara.jpg" }) {
                childImageSharp {
                    fixed(width: 169, height: 232) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            barry: file(relativePath: { eq: "barry.jpg" }) {
                childImageSharp {
                    fixed(width: 169, height: 194) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            cheryl: file(relativePath: { eq: "cheryl.jpg" }) {
                childImageSharp {
                    fixed(width: 169, height: 194) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            cd: file(relativePath: { eq: "cd.jpg" }) {
                childImageSharp {
                    fixed(width: 169, height: 170) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            maxime: file(relativePath: { eq: "maxime.jpg" }) {
                childImageSharp {
                    fixed(width: 169, height: 194) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            nick: file(relativePath: { eq: "nick.jpg" }) {
                childImageSharp {
                    fixed(width: 169, height: 194) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            lorene: file(relativePath: { eq: "lorene.jpg" }) {
                childImageSharp {
                    fixed(width: 169, height: 177) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            concertposter: file(relativePath: { eq: "concertposter.jpg" }) {
                childImageSharp {
                    fixed(width: 372, height: 481) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `;

    const render = (data) => {
        switch (filename) {
            case "choir.jpg":
                return (<Img fixed={data.bigchoir.childImageSharp.fixed}/>);
            case "choir_christmas.jpg":
                return (<Img fixed={data.choir_christmas.childImageSharp.fixed}/>);
            case "chamber.jpg":
                return (<Img fixed={data.chamber.childImageSharp.fixed}/>);
            case "neptunes.jpg":
                return (<Img fixed={data.neptunes.childImageSharp.fixed}/>);
            case "persephones.jpg":
                return (<Img fixed={data.persephones.childImageSharp.fixed}/>);
            case "barbara.jpg":
                return (<Img fixed={data.barbara.childImageSharp.fixed}/>);
            case "barry.jpg":
                return (<Img fixed={data.barry.childImageSharp.fixed}/>);
            case "cheryl.jpg":
                return (<Img fixed={data.cheryl.childImageSharp.fixed}/>);
            case "cd.jpg":
                return (<Img fixed={data.cd.childImageSharp.fixed}/>);
            case "lorene.jpg":
                return (<Img fixed={data.lorene.childImageSharp.fixed}/>);
            case "maxime.jpg":
                return (<Img fixed={data.maxime.childImageSharp.fixed}/>);
            case "nick.jpg":
                return (<Img fixed={data.nick.childImageSharp.fixed}/>);
            case "concertposter.jpg":
                return (<Img fixed={data.concertposter.childImageSharp.fixed}/>);
            default:
                return "Unknown image " + filename;
        }
    };

    return (
        <StaticQuery query={query} render={render}/>
    );
};

import React from "react"
import {Carousel, CarouselControl, CarouselIndicators, CarouselItem, Col, Container, Row} from "reactstrap";
import {ImageLoader} from "./ImageLoader";
import {MDXRenderer} from "gatsby-plugin-mdx";
import {Icon} from "./Icon";

export class HomeCarousel extends React.Component {

    constructor(props) {
        super(props);

        this.carouselItems = props.items.edges.map(({node}) => ({...node, src: node.id}));
        this.state = {
            activeIndex: 0,
        };
    }

    next = () => {
        const nextIndex = this.state.activeIndex === this.carouselItems.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({activeIndex: nextIndex});
    };

    prev = () => {
        const nextIndex = this.state.activeIndex === 0 ? this.carouselItems.length - 1 : this.state.activeIndex - 1;
        this.setState({activeIndex: nextIndex});
    };

    goToIndex = (newIndex) => {
        this.setState({activeIndex: newIndex});
    };

    render() {
        const slides = this.carouselItems.map(item => {
            return (
                <CarouselItem
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                    key={item.id}
                >
                    <Container>
                        <Row>
                            <Col sm="12" md="6">
                                <div className="carousel-caption">
                                    <div className="carousel-text">
                                        <h1 className="animated fadeInDownBig">{item.frontmatter.title}</h1>
                                        <ul className="list-unstyled carousel-list">
                                            <MDXRenderer>{item.body}</MDXRenderer>
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={6} md={16}>
                                <div className="carousel-img">
                                    <ImageLoader filename={item.frontmatter.imageUrl}/>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </CarouselItem>
            );
        });

        return (
            <React.Fragment>
                <Carousel className="carousel-mind" activeIndex={this.state.activeIndex} next={this.next}
                          previous={this.prev}>
                    <CarouselIndicators items={this.carouselItems} activeIndex={this.state.activeIndex}
                                        onClickHandler={this.goToIndex}/>
                    {slides}
                    <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.prev}/>
                    <CarouselControl direction="next" directionText="Next" onClickHandler={this.next}/>
                </Carousel>
            </React.Fragment>
        )
    }
}

export const SlideItem = (props) => {
    return (
        <li className="animated bounceInLeft">
            <span><Icon props/> {props.text}</span>
        </li>
    )
};

export const SlideLink = (props) => {
    return (
        <li className="animated bounceInLeft">
            <a href={props.url} className="btn btn-primary btn-lg">
                {props.title}
            </a>
        </li>
    )
};

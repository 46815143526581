import React from "react"
import {ImageLoader} from "./ImageLoader";
import {Card, CardBody, CardHeader, CardText, Col} from "reactstrap";

export const Panel = (props) => {
    const {image, title, subtitle, children, width} = props;

    return (
        <Col md={width}>
            <section>
                <Card>
                    <CardHeader className="section-title align-middle">
                        <h6 className="float-right" style={{lineHeight: "33.6px"}}>{subtitle}</h6>
                        <h3>{title}</h3>
                    </CardHeader>
                    <CardBody>
                        {image && (<div className="alignleft"><ImageLoader filename={image}/></div>)}
                        {children}
                    </CardBody>
                </Card>
            </section>
        </Col>
    )
};

export const Text = (props) => {
    const {children} = props;

    return (
        <CardText>{children}</CardText>
    )
};

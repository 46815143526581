import React from "react"
import {FaAngleDoubleRight, FaBullhorn, FaCalendar, FaTree} from "react-icons/fa"

export const Icon = (props) => {
    switch (props.icon) {
        case "bullhorn":
            return (<FaBullhorn/>);
        case "calendar":
            return (<FaCalendar/>);
        case "tree":
            return (<FaTree/>);
        case "angle":
            return (<FaAngleDoubleRight/>);
        default:
            return null;
    }
};
